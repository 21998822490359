import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model";
import { IconPath } from '../../common/icon';
import React from 'react';

export interface HomeProps {}

export const GoalsTemplate: React.FC<HomeProps> = (props: HomeProps) => {
  return (
    <>
      <div
        role="main"
        data-name="goal"
        id="goal"
        className="xl:max-w-7xl mx-auto flex xl:h-screen xsm:px-8 lg:px-0 items-center lg:flex-row xsm:flex-col"
      >
        <div className="relative px-12 lg:px-12 xl:w-1/2 lg:w-full md:flex-col">
          <div className="mx-auto max-w-2xl py-12 sm:py-4 lg:py-4">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
            <div className="text-left">
              <label htmlFor="easyToUse" className="text-primary">
                Easy to use
              </label>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Guac helps you save money faster
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our auto-tip technology and percentage-based savings are proven
                to save you more money than roundup apps.
              </p>
              <div className="mt-10 flex items-center justify-start gap-x-6">
                <a
                  href="https://app.guacapp.com/"
                  className="text-base font-semibold leading-7 bg-primary px-8 py-4 rounded-md text-white"
                  aria-label="Get Started"
                >
                  Get Started <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="xsm:mx-auto xsm:w-full xl:w-1/2">
          <img
            src={require('../../assets/goals.png')}
            alt="Goals overview screenshot"
          />
        </div>
      </div>
    </>
  );
};
